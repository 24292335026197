import 'es6-promise/auto'
//import 'regenerator-runtime/runtime'
import 'core-js'

import {getOptions} from './rest-service/rest-service'

const OutdatedBrowsers = () => {

  const lang = document.documentElement.lang.split('-')[0]

  getOptions( lang, (response) => {
    // create a div (could also reuse a known element)
    const el = document.getElementById('header')
    // detect support

    const supports_grid = typeof el.style.grid === 'string'

    const outdatedConsent = localStorage.getItem('outdatedConsent')

    if (!supports_grid && outdatedConsent !== 'yes') {
      const outdated = document.createElement('DIV')
      outdated.classList.add('outdated')

      const buttonMarkup = `<button class="button outdated__button">
        <i class="icon icon-close">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">
            <path fill="currentColor" d="M17.2,15L29.5,2.7c0.6-0.6,0.6-1.6,0-2.2c-0.6-0.6-1.6-0.6-2.2,0L15,12.8L2.7,0.5c-0.6-0.6-1.6-0.6-2.2,0
            \ts-0.6,1.6,0,2.2L12.8,15L0.5,27.3c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0L15,17.2l12.3,12.3c0.6,0.6,1.6,0.6,2.2,0
            \tc0.6-0.6,0.6-1.6,0-2.2L17.2,15z"/>
          </svg>
        </i>
      </button>`

      outdated.insertAdjacentHTML('beforeend', buttonMarkup)

      const outdatedInner = document.createElement('DIV')
      outdatedInner.classList.add('outdated__inner', 'body', 'font-color-white')
      outdatedInner.innerHTML = response.data.outdated_browsers_text.toString()

      outdated.appendChild(outdatedInner)

      /*const figure = document.createElement('FIGURE')
      figure.id = 'outdated__figure'
      figure.classList.add('outdated__figure')
      const img = document.createElement('IMG')
      img.id = 'outdated__img'
      img.crossOrigin = 'Anonymous'
      img.src = response.outdated_browsers_image.sizes.fullscreen

      figure.appendChild(img)

      outdated.insertAdjacentElement('beforeend', figure)*/

      document.body.appendChild(outdated)

      const outdatedCloseButton = document.querySelector('.outdated__button')

      outdatedCloseButton.addEventListener('click', () => {
        document.body.removeChild(outdated)
        localStorage.setItem('outdatedConsent', 'yes')
      }, false)
    }
  })
}

OutdatedBrowsers()
